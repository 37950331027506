.buttonBlock>div {
    margin: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.formData {
    width: 70%;
    margin: 0 15%;
}

.formData .ant-picker.ant-picker-large,
.formData .ant-input-number.ant-input-number-lg {
    width: 100%;
}

.formData .questionBlock {
    background-color: #e6eff982;
    margin-bottom: 20px;
    border-radius: 15px;
    border: 1px solid #CCC;
}

.formData .questionBlock .ant-card-head {
    border-radius: 15px 15px 0 0;
}

.formData .questionBlock .ant-card-head,
.formData .questionBlock .ant-card-body,
.formData .buttonBlock .ant-btn-block:hover {
    background-color: #e6eff982;
}

.formData .radioButton,
.formData .checkBoxGroup {
    width: 100%;
}

.formData .radioButton>*,
.formData .checkBoxGroup>* {
    width: 33%;
    padding-right: 10px;
    margin-right: 0;
}

.formData span.anticon.anticon-up.ant-input-number-handler-up-inner svg,
.formData span.anticon.anticon-down.ant-input-number-handler-down-inner svg {
    max-width: 12px;
    max-height: 12px;
}

.formData .ant-checkbox-checked .ant-checkbox-inner,
.formData .ant-radio-inner {
    border-color: #000;
}

.formData .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #1890ff;
}

.formData .photoInput {
    display: flex;
}
.formData .photoInput input {
    max-height: 50px;
}
.formData .photoInput img {
    padding-left: 50px;
    max-width: 300px;
}